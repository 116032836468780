import React from 'react'
import { useIntl } from 'react-intl'
import { useCookie } from 'msp-integrations'
import { ShowOnDesktop, ShowOnMobile, Icons } from 'msp-components'
import { UserHeaderIconProps } from './UserHeaderIcon.types'

const UserHeaderIcon = ({
  className,
  onClick,
  iconSize,
  isAuthenticated,
  customerName = ''
}: UserHeaderIconProps) => {
  const { eventTracking } = useCookie()
  const { formatMessage } = useIntl()

  const label = formatMessage({
    id: 'header.button.assistive_msg.my_account',
    defaultMessage: 'My account'
  })

  const handleClick = () => {
    eventTracking('header_tracking', { event_action: 'login' })
    onClick && onClick()
  }

  return (
    <div
      className={`font-GoodPro text-14 tracking-0.2 flex items-center font-medium ${className}`}
    >
      <button
        className='focus:outline-none'
        aria-label={label}
        onClick={() => handleClick()}
        data-cy={'user-button-header'}
      >
        {isAuthenticated ? (
          <>
            <ShowOnDesktop>
              <div className={'flex flex-row'}>
                <div
                  className={
                    'max-w-[100px] overflow-hidden truncate align-middle'
                  }
                >
                  {customerName || label}
                </div>
                <Icons
                  iconName={'ArrowDown'}
                  height={iconSize}
                  width={iconSize}
                />
              </div>
            </ShowOnDesktop>
            <ShowOnMobile>
              <Icons
                iconName={'UserAccount'}
                height={iconSize}
                width={iconSize}
              />
            </ShowOnMobile>
          </>
        ) : (
          <Icons iconName={'UserAccount'} height={iconSize} width={iconSize} />
        )}
      </button>
    </div>
  )
}

export default UserHeaderIcon
