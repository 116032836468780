import React, { ComponentType, useState, useEffect } from 'react'
import { useLocation, useParams, Redirect } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import {
  useSfraApiPage,
  Region,
  transformRegionToMap,
  useMultiSite,
  usePageDesignerEditMode,
  useCustomer,
  useCookie
} from 'msp-integrations'
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import PuroHeaderCheckout from '@/components/molecules/PuroHeaderCheckout/PuroHeaderCheckout'
import { Spinner, FooterCheckout, ScrollTo } from 'msp-components'
import AccessForm from '@/components/molecules/AccessForm/AccessForm'
import { parseCookie, setCookie } from '@/utils/cookie-utils'

const formatToOriginalPath = (
  site: { id: string; alias?: string },
  locale: { id: string; alias?: string },
  location: { pathname: string }
) => {
  let originalPath = location.pathname
  originalPath = originalPath.replace(`/${site.alias || site.id}`, '')
  originalPath = originalPath.replace(`/${locale.alias || locale.id}`, '')
  if (originalPath.charAt(originalPath.length - 1) === '/') {
    originalPath = originalPath.substring(0, originalPath.length - 1)
  }
  return originalPath
}

const getPageIdOrSlug = (
  originalPath: string,
  params: { categoryId: string; productPath: string }
) => {
  const { categoryId, productPath } = params
  let pageIdOrSlug = null
  const productId = productPath ? productPath.match(/\d{18}/)[0] : undefined

  if (categoryId) {
    pageIdOrSlug = `category-${categoryId}`
  } else if (!categoryId && originalPath.indexOf('/shop') !== -1) {
    pageIdOrSlug = 'category-root'
  } else if (productId) {
    pageIdOrSlug = `product-${productId}`
  } else if (originalPath === '') {
    pageIdOrSlug = 'home'
  } else {
    pageIdOrSlug = originalPath.substring(1)
  }
  pageIdOrSlug = pageIdOrSlug.split('#')[0] || pageIdOrSlug

  return pageIdOrSlug
}

type PageLayoutTypes = {
  ChildPage: ComponentType<any>
  isCheckoutPage: boolean
  isMaintenancePage: boolean
}

const PageLayout = (props: PageLayoutTypes) => {
  const { ChildPage, isCheckoutPage, isMaintenancePage, ...rest } = props
  const location = useLocation()
  const params = useParams() as {
    categoryId: string
    productPath: string
  }

  const customer = useCustomer()
  const { site, locale, buildUrl } = useMultiSite()
  const { setCookiesAllowed } = useCookie()

  const originalPath = formatToOriginalPath(site, locale, location)
  const pageIdOrSlug = getPageIdOrSlug(originalPath, params)

  const pageDesignerEditMode = usePageDesignerEditMode()
  const { app: appConfig } = getConfig()
  const accessKey = appConfig?.frontend?.access?.accessKey
  const salt = appConfig?.frontend?.access?.salt
  const { isLoading, data: page } = useSfraApiPage({
    query: { pageIdOrSlug: pageIdOrSlug }
  })

  // Get native app state for show/hide elements
  const queryClient = useQueryClient()
  const { appMode, hideHeader, hideFooter, cookieHandler }: any =
    queryClient.getQueryData(['native-app-state'])

  const [showAuth, setShowAuth] = useState({
    load:
      accessKey &&
      accessKey.length > 0 &&
      salt.length > 0 &&
      salt !== 'undefined' &&
      !pageDesignerEditMode &&
      !(appMode === '1'),
    render: false,
    accessKey: null
  })

  useEffect(() => {
    if (customer.isRegistered || customer.isGuest) {
      queryClient.invalidateQueries({
        queryKey: ['sfraApiPage'],
        refetchType: 'active'
      })
    }
  }, [customer.authType])

  useEffect(() => {
    if (cookieHandler) {
      if (cookieHandler === 'accept-all') {
        setCookiesAllowed({
          marketing: true,
          performance: true,
          functional: true,
          required: true
        })
      }

      if (cookieHandler === 'reject-all') {
        setCookiesAllowed({
          marketing: false,
          performance: false,
          functional: false,
          required: true
        })
      }
    }
  }, [])

  useEffect(() => {
    if (pageDesignerEditMode) {
      setTimeout(function () {
        setCookiesAllowed({
          marketing: true,
          performance: true,
          functional: true,
          required: true
        })
      }, 25)
    }
  }, [pageDesignerEditMode])

  useEffect(() => {
    const cookies = window.document.cookie
      ? parseCookie(window.document.cookie)
      : {}

    if (showAuth.load && !showAuth.render) {
      setShowAuth({
        load: false,
        render: showAuth.load && cookies.accessKey !== accessKey,
        accessKey: cookies.accessKey
      })
    }

    setCookie('appMode', appMode, 7)
  }, [showAuth])

  const regions = transformRegionToMap(page?.content.regions)

  // Redirect Page Designer pages fetched by id to url, if available
  if (ChildPage.name === 'Page' && page) {
    if (
      page.pageFetchedById &&
      page.url &&
      `/${page.id}` !== page.url &&
      page.url !== '/' &&
      page.found &&
      !pageDesignerEditMode
    ) {
      return <Redirect to={buildUrl(page.url)} />
    }
  }

  return (
    <div id='page' style={{ visibility: showAuth.load ? 'hidden' : 'visible' }}>
      {!showAuth.render && regions ? (
        <>
          {!hideHeader && (
            <div className='pt-[66px] md:pt-[52px]'>
              <header>
                {((isCheckoutPage || isMaintenancePage) &&
                  (regions.get('header')?.components &&
                  regions.get('header').components[0] ? (
                    <PuroHeaderCheckout
                      regions={regions.get('header').components[0].regions}
                      disableNavigation={isMaintenancePage}
                    />
                  ) : (
                    <PuroHeaderCheckout
                      regions={[]}
                      disableNavigation={isMaintenancePage}
                    />
                  ))) || <Region region={regions.get('header')} />}
              </header>
            </div>
          )}
          <main id='main-content'>
            {!isMaintenancePage && (
              <>
                {regions.get('banner') && (
                  <div id='banners' className='sticky top-0 z-50'>
                    <Region region={regions.get('banner')} noWrapper={true} />
                  </div>
                )}
                {regions.get('topRegion') && (
                  <div id='top-content'>
                    <Region region={regions.get('topRegion')} />
                  </div>
                )}
              </>
            )}
            {!page || isLoading ? (
              <Spinner fullScreen />
            ) : (
              <ChildPage
                {...rest}
                page={page}
                pageNotFound={!isLoading && !page?.found}
              />
            )}
            <Region region={regions.get('bottomRegion')} />
          </main>
          {!hideFooter && (
            <footer>
              {(isCheckoutPage || isMaintenancePage) &&
              regions.get('footer')?.components &&
              regions.get('footer').components[0] ? (
                <FooterCheckout
                  regions={regions.get('footer').components[0].regions}
                  disableNavigation={isMaintenancePage}
                />
              ) : (
                <Region region={regions.get('footer')} />
              )}
            </footer>
          )}
        </>
      ) : (
        <>
          {showAuth.render && <AccessForm accessKey={accessKey} salt={salt} />}
        </>
      )}
      {!isLoading && <ScrollTo to='anchor' />}
    </div>
  )
}

PageLayout.getTemplateName = () => 'page-layout'

PageLayout.shouldGetProps = () => false

PageLayout.getProps = async () => {}

export default PageLayout
